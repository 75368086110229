import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import SectionHeading from '../components/sectionHeading'
import drone from "../media/videos/drone.mp4"
import brixton from "../media/videos/brixton.mp4"
import cedar from "../media/videos/cedar.mp4"
import wardlaw from "../media/videos/wardlaw.mp4"
import "../styles/index.scss"
import { SEO } from "../components/seo"

const VideoExamples = props => {
  return (
    <Layout pageTitle="Videos">
        <div className="videoContainer">
            <div className="videoItem">
                <video controls className="videoExample"
                    type="video/mov" >
                    <source 
                    src={brixton} 
                    />
                </video>
            </div>
            <div className="videoItem">
                <video controls className="videoExample"
                    type="video/mov" >
                    <source 
                    src={cedar} 
                    />
                </video> 
            </div>
            <div className="videoItem">
                <video controls className="videoExample"
                    type="video/mov" >
                    <source 
                    src={wardlaw} 
                    />
                </video>
            </div>
        </div>       
    </Layout>
  )
}

export const Head = () => (
    <SEO title="Video examples" />
  )

export default VideoExamples;